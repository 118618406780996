import { Controller } from "@hotwired/stimulus"
//import { Hljs } from 'highlight.js/lib/common';

import hljs from 'highlight.js/lib/core';
// To highlight code with a specific language, use highlight:
import json  from 'highlight.js/lib/languages/json';
import javascript  from 'highlight.js/lib/languages/javascript';    
import python  from 'highlight.js/lib/languages/python';  
import ruby  from 'highlight.js/lib/languages/ruby'; 


export default class extends Controller {

  static targets = [ "language", "source", "display"]

  // Best in place initialisation
  connect() {
    this.updateCode(); 
  } 
  updateCode(){

    //let lang = this.languageTarget; 
    let source = this.sourceTarget.value;
    let lang = this.languageTarget.options[this.languageTarget.selectedIndex].value;
    console.log("highilight ! ", lang)

    if(lang === "ruby"){ 
      hljs.registerLanguage('ruby', ruby);  
    }
    if(lang === "python"){ 
      hljs.registerLanguage('python', python);  
    }
    if(lang === "json"){ 
      hljs.registerLanguage('json', json);  
    }
    if(lang === "javascript"){ 
      hljs.registerLanguage('javascript', javascript);  
    }

    let html = hljs.highlight(source, {language: lang}).value
    this.displayTarget.innerHTML = html;
  }

}
