import { Controller } from "@hotwired/stimulus"
import { Base64 } from "js-base64";
import vegaEmbed from 'vega-embed'

let chartConfig = {
  "$schema": "https://vega.github.io/schema/vega-lite/v5.json",
  "background": "rgba(0, 0, 0, 0)",
  "config": {
    "axis": {
      "labelFont": "Quicksand",
      "labelFontSize": 14,
      "titleFont": "Montserrat",
      "titleFontSize": 16,
      "labelAngle": 0
    }
  },
  "transform": [
    {
      "filter": { "field": "group" }
    }
  ],
  "mark": {
    "type": "line",
    "point": true,
    "tooltip": true,
    "cursor": "pointer"
  },
  "encoding": {
    "x": {
      "field": "name",
      "type": "ordinal",
      "sort": null
    },
    "y": {
      "title": "Mean",
      "aggregate": "mean",
      "field": "value",
      "type": "quantitative"
    },
    "tooltip": [
      { "field": "name" },
      { "field": "value", "aggregate": "count" },
      { "field": "value", "aggregate": "mean" },
      { "field": "group" },
      { "field": "serie" }
    ],
    "xOffset": { "field": "group" },
    "color": {
      "field": "group",
      "legend": null,
    }
  }
};

export default class extends Controller {

  connect() {
    super.connect();
  }

  disconnect() {
    if (this.vegaChart) 
      this.vegaChart.finalize();
  }

  setParameters(chartContainer, groups, palettes, width, height) {
    this.chartContainer = chartContainer;
    this.groups = groups;

    chartConfig.encoding.color.scale = { "range": palettes, "domain": groups };
    chartConfig.width = width;
    chartConfig.height = height;
  }

  displayChart(rawChartData) {
    const chartData = Base64.decode(rawChartData);
    const chartDataArray = JSON.parse(chartData).filter(f => f.name == "Moyenne Cognitif" || f.name == "Score Total Cybermalaise" );
    let displayableIds = [];
    
    for (let i = 0; i < this.groups.length; i++) {
      let checkbox = document.getElementById(`mapping-checkbox-${i}`);
      displayableIds.push(i);

      checkbox.addEventListener("click", () => {
        if (!checkbox.checked)
          displayableIds.splice(displayableIds.indexOf(Number(checkbox.value)), 1);
        else
          displayableIds.push(Number(checkbox.value));

        this.updateChart(chartDataArray, displayableIds);
      });
    }

    this.updateChart(chartDataArray, displayableIds);
  }

  updateChart(chartDataArray, displayableIds) {
    chartConfig.data = { values: chartDataArray };
    chartConfig.transform[0].filter.oneOf = this.groups.filter(n => displayableIds.includes(this.groups.indexOf(n)));

    vegaEmbed(this.chartContainer, chartConfig, { actions: false }).then((result) => {
      this.vegaChart = result;
    });
  }
}
