import { Controller } from "@hotwired/stimulus"
import { Base64 } from "js-base64";
import vegaEmbed from 'vega-embed'

let chartConfig = {
  "data": { "name": "medians" },
  "background": "rgba(0, 0, 0, 0)",
  "config": {
    "axis": {
      "labelFont": "Quicksand",
      "labelFontSize": 14,
      "titleFont": "Quicksand",
      "titleFontSize": 20
    }
  },
  "encoding": {
    "y": {
      "field": "name",
      "type": "nominal",
      "sort": null,
      "axis": {
        "domain": false,
        "offset": 50,
        "labelFontWeight": "bold",
        "ticks": false,
        "grid": true,
        "title": null
      }
    },
    "x": {
      "type": "quantitative",
      "scale": { "domain": null },
      "axis": {
        "grid": false,
        "title": null
      }
    },
  },
  "view": { "stroke": null },
  "layer": []
};

export default class extends Controller {

  connect() {
    super.connect();
  }

  disconnect() {
    if (this.vegaChart)
      this.vegaChart.finalize();
  }

  setParameters(chartContainer, groups, palettes, width, height) {
    this.chartContainer = chartContainer;
    this.groups = groups;
    this.palettes = palettes;

    this.groupsOffset = 20;
    this.legendOffset = 40;

    chartConfig.width = width;
    chartConfig.height = height;
  }

  displayChart(rawChartData) {
    const chartData = Base64.decode(rawChartData);
    const chartDataArray = JSON.parse(chartData).filter(f => f.name.toLowerCase().includes("question") );
    let displayableIds = [];

    this.setMinMax(chartDataArray);

    for (let i = 0; i < this.groups.length; i++) {
      let select = document.getElementById(`mapping-select-${i}`);
      let checkbox = document.getElementById(`mapping-checkbox-${i}`);
      displayableIds.push(i);

      select.addEventListener("click", () => {
        chartConfig.layer[i].mark.type = select.value;
        this.updateChart(chartDataArray);
      });

      checkbox.addEventListener("click", () => {
        if (!checkbox.checked)
          displayableIds.splice(displayableIds.indexOf(Number(checkbox.value)), 1);
        else
          displayableIds.push(Number(checkbox.value));

        chartConfig = this.processLayers(chartConfig, displayableIds);
        this.updateChart(chartDataArray);
      });
    }

    chartConfig = this.processLayers(chartConfig, displayableIds);
    this.updateChart(chartDataArray);
  }

  setMinMax(chartDataArray) {
    let axisValues = [];
    let max = -Infinity;
    let min = Infinity;

    for (let k in chartDataArray) {
      max = chartDataArray[k].value > max ? chartDataArray[k].value : max;
      min = chartDataArray[k].value < min ? chartDataArray[k].value : min;
    }

    for (let i = min; i <= max; i++)
      axisValues.push(i)

    chartConfig.encoding.x.scale.domain = [min, max];
    chartConfig.encoding.x.axis.values = axisValues;
  }

  updateChart(chartDataArray) {
    chartConfig.datasets = { values: chartDataArray }

    vegaEmbed(this.chartContainer, chartConfig, { actions: false }).then((result) => {
      this.vegaChart = result;
    });
  }

  processLayers(chartConfig, displayableIds) {
    chartConfig.layer = [];

    for (let i = 0; i < this.groups.length; i++) {
      if (displayableIds.includes(i)) {
        let select = document.getElementById(`mapping-select-${i}`);

        let currentLayer = {
          "id": i,
          "params": [{
            "name": `highlight${i}`,
            "select": {
              "type": "point",
              "on": "mouseover"
            }
          }],
          "mark": {
            "type": select.value,
            "tooltip": true,
            "cursor": "pointer"
          },
          "data": { "name": "values" },
          "transform": [{
            "filter": `datum.group == ${this.groups[i]}`
          }],
          "encoding": {
            "x": { "field": "value" },
            "size": {
              "aggregate": "count",
              "type": "quantitative",
              "legend": { "offset": this.legendOffset + this.groupsOffset * this.groups.length }
            },
            "xOffset": { "value": this.groupsOffset * i },
            "color": {
              "value": this.palettes[i % this.palettes.length],
              "condition": [{
                "param": `highlight${i}`,
                "empty": false,
                "value": "black"
              }]
            }
          }
        };

        chartConfig.layer.push(currentLayer);
      }
    }

    return chartConfig;
  }
}