import { Controller } from "@hotwired/stimulus"
import { Base64 } from "js-base64";
import vegaEmbed from 'vega-embed';

// TODO: find a place for the palettes, and update them.
const palettes = ["#1f77b4", "#ff7f0e", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#7f7f7f", "#bcbd22", "#17becf"];

export default class extends Controller {

  static values = {
    editor: Boolean,
    configuration: String,
    patchConfiguration: String,
    activatedNames: Array,
    activatedGroupTps: Array
  };

  static targets = ["mounting", "checkboxes"];

  initialize() {
    if (this.hasConfigurationValue) {
      const chartConfig = Base64.decode(this.configurationValue);
      this.chartConfig = JSON.parse(chartConfig);
    } else {
      console.log("ERR.load default vega bar chart configuration.");
    }

    if (this.hasPatchConfigurationValue) {
      let patch = JSON.parse(Base64.decode(this.patchConfigurationValue));
      Object.assign(this.chartConfig, patch);
    }
  }

  setParameters() {
    this.groupsOffset = 20;
    this.legendOffset = 40;

    // TODO: update color scales
    if (this.chartConfig.mark && !this.editorValue)
      this.chartConfig.encoding.color.scale = { "range": palettes, "domain": this.activatedGroupTpsValue };
  }

  displayChart(rawChartData) {
    const chartData = Base64.decode(rawChartData);
    this.chartDataArray = JSON.parse(chartData);

    if (this.checkboxesTargets.length > 0)
      this.addCheckboxListeners();

    this.updateChart();
  }

  updateChart() {
    if (this.chartConfig.mark)
      this.chartConfig.data = { values: this.chartDataArray };
    else {
      this.chartConfig.datasets = { values: this.chartDataArray };
      this.setMinMax();
      this.processLayers();
    }

    // Question activations 
    this.chartConfig.transform = []
    this.chartConfig.transform[0] = { filter: { field: "name", oneOf: this.activatedNamesValue } };

    if (this.hasActivatedGroupTpsValue)
      this.chartConfig.transform[1] = { filter: { field: "group_tp", oneOf: this.activatedGroupTpsValue } };

    let target = this.element;

    if (this.hasMountingTarget)
      target = this.mountingTarget;

    let parameters = {
      actions: {
        export: true,
        source: false,
        compiled: false,
        editor: false
      }
    };

    if (this.editorValue) {
      parameters.actions.export = false;
      parameters.actions.editor = true;
    }

    vegaEmbed(target, this.chartConfig, parameters).then((result) => {
      this.vegaChart = result;
    });
  }

  addCheckboxListeners() {
    let i = 0;
    for (let checkbox of this.checkboxesTargets) {
      let checkbox_name = checkbox.id.split("_");
      let groupTp = checkbox_name[checkbox_name.length - 1];

      checkbox.style.accentColor = palettes[i % palettes.length];

      checkbox.addEventListener("click", () => {
        // To update the value, it must be explicitly set at the end.
        let activations = this.activatedGroupTpsValue;

        if (!checkbox.checked)
          // delete the element from the array
          activations.splice(activations.indexOf(groupTp), 1);
        else
          // add it back
          activations.push(groupTp);

        this.activatedGroupTpsValue = activations.sort();
        this.updateChart();
      });
      i++;
    }
  }

  setMinMax() {
    let axisValues = [];
    let groupTps = [];
    let max = -Infinity;
    let min = Infinity;

    for (let k in this.chartDataArray) {
      if (this.activatedNamesValue.includes(this.chartDataArray[k].name)) {
        max = this.chartDataArray[k].value > max ? this.chartDataArray[k].value : max;
        min = this.chartDataArray[k].value < min ? this.chartDataArray[k].value : min;
        groupTps.push(this.chartDataArray[k].group_tp);
      }
    }

    for (let i = min; i <= max; i++)
      axisValues.push(i)

    this.groupTps = [...new Set(groupTps)];
    this.chartConfig.encoding.x.scale.domain = [min, max];
    this.chartConfig.encoding.x.axis.values = axisValues;
  }

  processLayers() {
    this.chartConfig.layer = [];

    let baseLayerConfig = {
      "params": [{
        "name": `highlight`,
        "select": {
          "type": "point",
          "on": "mouseover"
        }
      }],
      "transform": [{
        "filter": {
          "field": "name",
          "oneOf": this.activatedNamesValue
        }
      }],
      "mark": {
        "type": "circle",
        "tooltip": true,
        "cursor": "pointer"
      },
      "data": { "name": "values" },
      "encoding": {
        "x": { "field": "value" },
        "tooltip": [
          { "field": "name" },
          { "field": "value", "aggregate": "count" },
          { "field": "group" },
          { "field": "time_period" }
        ],
        "size": {
          "aggregate": "count",
          "type": "quantitative",
          "legend": { "offset": this.legendOffset + this.groupsOffset * this.groupTps.length }
        },
        "color": {
          "condition": [{
            "param": `highlight`,
            "empty": false,
            "value": "black"
          }]
        }
      }
    };

    if (this.editorValue)
      this.chartConfig.layer.push(baseLayerConfig);
    else {
      for (let i = 0; i < this.activatedGroupTpsValue.length; i++) {
        let currentLayer = JSON.parse(JSON.stringify((baseLayerConfig)));

        currentLayer.params[0].name = `highlight${i}`;
        currentLayer.transform.push({ "filter": { "field": "group_tp", "equal": this.activatedGroupTpsValue[i] } });
        currentLayer.encoding.xOffset = { "value": this.groupsOffset * i };
        currentLayer.encoding.color.condition[0].param = `highlight${i}`;
        currentLayer.encoding.color.value = palettes[this.groupTps.indexOf(this.activatedGroupTpsValue[i]) % palettes.length];

        this.chartConfig.layer.push(currentLayer);
      }
    }
  }

  disconnect() {
    if (this.vegaChart)
      this.vegaChart.finalize();
  }
}
